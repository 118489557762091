import React, { useState, useEffect, useRef } from "react";
import BikeService from "../services/bike.service";
import EventBus from "../common/EventBus";
import { NavLink } from "react-router-dom";
import DateFromatter from "../common/DateFromatter";
import MaintenanceList from "../commonComponents/MaintenanceList";
import RemovedInventories from "../containers/removedInventories/RemovedInventories";

const currentUser = JSON.parse(localStorage.getItem("user"));
const Bike = (props) => {
  const form = useRef();
  const checkBtn = useRef();

  const [number, setNumber] = useState("");
  const [model, setModel] = useState("");
  const [kilometers, setKilometers] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [logs, setLogs] = useState("");
  const [inventory, setInventory] = useState("");
  const [inventories, setInventories] = useState([]);
  const [removedInventories, setRemovedInventories] = useState([]);

  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [branch, setBranch] = useState("");
  const bikeId = props.match.params.id;

  const [note, setNote] = useState("");
  const [stolenDate, setStolenDate] = useState(new Date());

  const loadBike = () => {
    BikeService.getBikeDetailsById(bikeId).then(
      async (response) => {
        let b = response.data;
        // console.log(b)
        setNumber(b.number);
        setModel(b.model);
        setKilometers(b.kilometers);
        setDescription(b.description);
        setLogs(b.logs);
        setStatus(b.status);
        setBranch(b.branch);
        setInventories(b.inventories);
        setRemovedInventories(b.removedInventories);
        setLoaded(true);
        setNote(b.notes);
        setStolenDate(b.stolenDate);
      },
      (error) => {
        const _content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        alert(_content);
        setMessage(_content);

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  };

  return (
    <div className="col-md-12">
      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "50%" }}>Bike Specification</th>
            <th style={{ float: "right" }}>
              {currentUser.type == "admin" || currentUser.type == "manager" ? (
                <>
                  <NavLink to={"/bike/edit/" + bikeId} className="text-dark ">
                    Edit
                  </NavLink>
                </>
              ) : (
                ""
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Number</td>
            <td>{number}</td>
          </tr>
          <tr>
            <td>Model</td>
            <td>{model ? model.label : ""}</td>
          </tr>
          <tr>
            <td>Odometers</td>
            <td>{kilometers}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{status}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{description}</td>
          </tr>
          <tr>
            <td>Branch</td>
            <td>{branch ? branch.label : ""}</td>
          </tr>
          <tr>
            <td>Notes</td>
            <td>{note ? note : ""}</td>
          </tr>
          {status === "Stolen" ? (
            <tr>
              <td>Stolen Date</td>
              <td>
                {stolenDate ? DateFromatter.DateFormatterOne(stolenDate) : ""}
              </td>
            </tr>
          ) : (
            ""
          )}
        </tbody>
      </table>

      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "33%" }}>Special Inventories</th>
          </tr>
        </thead>
        <tbody>
          {inventories &&
            inventories.map((i) => (
              <tr className="white-background">
                <td>
                  {i.number} {i.label}
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <RemovedInventories removedInventories={removedInventories} />

      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "33%" }}>Maintenance</th>
          </tr>
        </thead>
        <tbody>
          <tr className="white-background">
            <td>
              <MaintenanceList
                listView={true}
                showFilters={false}
                currentUser={currentUser}
                typeFilter="bike"
                bikeId={bikeId}
                isView={true}
                noResultsFound="noResultsFoundNoBorder"
                readyFunction={loadBike}
                statusFilter="all"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table className="styled-table" style={{ width: "100%" }}>
        <thead>
          <tr>
            <th style={{ width: "33%" }}>Logs</th>
          </tr>
        </thead>
        <tbody>
          <tr className="white-background">
            <td>
              <div dangerouslySetInnerHTML={{ __html: logs }}></div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Bike;
