import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import MaintenanceService from "../services/maintenance.service";
import EventBus from "../common/EventBus";
import { required } from "../common/Validation";
import {
  getInventoriesOptions,
  getBikersList,
  getAvailableBats,
  getAvailableChargers,
  getAvailableBikes,
  getBranches,
} from "../common/getFunctions";
import * as options from "../labels/options_en";
import RequiredLabel from "../common/RequiredLabel";
import OptionalLabel from "../common/OptionalLabel";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { Row, Col, Modal } from "react-bootstrap";
import Constants from "../core/Constants";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  InfoWindow,
  Marker,
} from "react-google-maps";
import axios from "axios";
import CommonService from "../services/common.service";
import NewSelect from "react-select";
import Functions from "../common/Functions";
import { Prompt } from "react-router-dom";
import RemovedInventories from "../containers/removedInventories/RemovedInventories";

export default function MaintenanceSaveForm(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  var userType = null;
  if (user) userType = user.type;

  const fix = props.location.pathname.includes("/fix/");

  const DoMaintenance =
    userType &&
    fix &&
    (userType == "technical" || userType == "manager" || userType == "admin")
      ? { display: "inline-table" }
      : { display: "none" };
  const HelpDesk =
    userType && (userType == "helpdesk" || userType == "admin")
      ? { display: "inline-table" }
      : { display: "none" };

  const colClassBasic =
    userType && userType == "technical" ? "form-group-col3" : "form-group-col4";

  const form = useRef();
  const checkBtn = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [successful, setSuccessful] = useState(false);
  const [saveClicked, setSaveClicked] = useState(false);
  const [message, setMessage] = useState("");
  const [maintenanceId, setMaintenanceId] = useState(props.match.params.id);
  const [type, setType] = useState("bike");
  const [action, setAction] = useState("repair");
  const [subcatergoryRequired, setSubcatergoryRequired] = useState(true);
  const [catergoryRequired, setCatergoryRequired] = useState(true);
  const [bikeFrom, setBikeFromNumber] = useState("");
  const [bikeId, setBikeId] = useState("");
  const [chargerId, setChargerId] = useState("");
  const [batId, setBatId] = useState("");
  const [number, setNumber] = useState({ value: "", label: "" });
  const [bikerId, setBikerId] = useState("");
  const [bikerName, setBikerName] = useState("");
  const [stringSize, setStringSize] = useState(0);
  const [category, setCategory] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [name, setName] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [status, setStatus] = useState("Maintenance");
  const [comments, setComments] = useState("");
  const [diagnose, setDiagnose] = useState("");
  const [odometer, setOdometer] = useState("");
  const [realNumber, setRealNumber] = useState("");
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(currentUser.branch);
  const [bikeList, setBikeList] = useState([
    { _id: "", number: "", full_model: "" },
  ]);
  const [chargerList, setChargerList] = useState([]);
  const [batList, setBatList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [bikeInventoryList, setBikeInventoryList] = useState([]);
  const [bikerList, setBikerList] = useState([]);
  const odometerRef = useRef();
  const [mainLogs, setMainLogs] = useState("");
  const [logs, setLogs] = useState("");
  const [company, setCompany] = useState("");
  const [missingInventories, setMissingInventories] = useState([]);
  const [repairedInventoriesInitial, setRepairedInventoriesInitial] = useState([
    "",
  ]);
  const [repairedInventoriesAdded, setRepairedInventoriesAdded] = useState([]);
  const [repairedInventoriesRemoved, setRepairedInventoriesRemoved] = useState(
    []
  );
  const [repairedInventories, setRepairedInventories] = useState([]);

  const [recycledInventoriesInitial, setRecycledInventoriesInitial] = useState([
    "",
  ]);
  const [recycledInventoriesAdded, setRecycledInventoriesAdded] = useState([]);
  const [recycledInventoriesRemoved, setRecycledInventoriesRemoved] = useState(
    []
  );
  const [recycledInventories, setRecycledInventories] = useState([]);

  const [lostInventoriesInitial, setlostInventoriesInitial] = useState([""]);
  const [lostInventoriesAdded, setlostInventoriesAdded] = useState([]);
  const [lostInventoriesRemoved, setlostInventoriesRemoved] = useState([]);
  const [lostInventories, setlostInventories] = useState([]);

  const [obsInventoriesInitial, setObsInventoriesInitial] = useState([""]);
  const [obsInventoriesAdded, setObsInventoriesAdded] = useState([]);
  const [obsInventoriesRemoved, setObsInventoriesRemoved] = useState([]);
  const [obsInventories, setObsInventories] = useState([]);

  const [specialInventoriesInitial, setSpecialInventoriesInitial] = useState([
    "",
  ]);
  const [specialInventoriesAdded, setSpecialInventoriesAdded] = useState([]);
  const [specialInventoriesRemoved, setSpecialInventoriesRemoved] = useState(
    []
  );
  const [returnToBike, setReturnToBike] = useState([]);
  const [specialInventories, setSpecialInventories] = useState([]);
  const [specialOptions, setSpecialOptions] = useState([]);
  const [subCatOptions, setSubCatOptions] = useState([]);
  const [maintenanceBikes, setMaintenanceBikes] = useState([]);
  const [branchClass, setBranchClass] = useState("");
  const clone = props.location.pathname.includes("/clone/");

  const [categoryOptions, setCategoryOptions] = useState([]);
  var categoryOptionsBike = [
    "Wheels",
    "Brakes",
    "Gears",
    "Body",
    "Lock",
    "Electronics",
    "Motor",
    "Accessories",
  ];
  var categoryOptionsBat = [
    "Not charging",
    "Draining",
    "Broken part",
    "Not turning on",
  ];
  var categoryOptionsCharger = [
    "Broken part",
    "Light not working",
    "Not charging",
  ];
  categoryOptions["Wheels"] = [
    "Inner Tube",
    "Rear hub",
    "Front hub",
    "Tube cap",
    "Tire",
    "Front wheel",
    "Rear wheel",
    "Inner Tube(decathlon)",
    "Tube caps",
    "Tire liner",
    "Rim",
    "Spoke",
    "Nipple",
    "Other",
  ];

  categoryOptions["Brakes"] = [
    "Brake pads",
    "Brake pads clamp",
    "Left brake lever",
    "Right brake lever",
    "Front caliper",
    "Rear caliper",
    "Brake disc",
    "Other",
  ];
  categoryOptions["Gears"] = [
    "Derailleur",
    "Hanger",
    "Shifter",
    "Chain",
    "Chain missing links",
    "Rear cassette gears",
    "Chain guard",
    "Chain ( Old type)",
    "Chain (New type)",
    "Other",
  ];
  categoryOptions["Body"] = [
    "Front fork",
    "Front fender",
    "Rear fender",
    "Fender extender",
    "Kickstand",
    "Saddle",
    "Rear rack",
    "Front rack",
    "Frame",
    "Clamp",
    "Seat post",
    "Handle bar",
    "Stem",
    "Phone holder",
    "Grip left",
    "Grip right",
    "Pedal Arm right",
    "Pedal Arm left",
    "Pedal right",
    "Pedal left",
    "Crankset",
    "Bell",
    "Seatpost",
    "Other",
  ];
  categoryOptions["Motor"] = ["Blue Gear", "Motor"];
  categoryOptions["Lock"] = ["Lock", "Lock chain", "Keys"];
  categoryOptions["Electronics"] = [
    "Front light",
    "Rear light",
    "Front light bracket",
    "Rear light connector",
    "Display",
    "Motor controller",
    "Usb cable",
    "Display cable",
    "Head set - spacers",
    "Other",
  ];
  categoryOptions["Accessories"] = ["Straps", "Other"];

  const [actionCategory, setActionCategory] = useState("");
  const [actionSubCatOptions, setActionSubCatOptions] = useState([]);
  const [actionSubCategory, setActionSubCategory] = useState("");
  const [actionComments, setActionComments] = useState("");
  const [fixAction, setFixAction] = useState("");
  const [inventory, setInventory] = useState("");
  const [actionsDone, setActionsDone] = useState([]);
  const [addOldPartTo, setAddOldPartTo] = useState("");
  const [selectedBikeInventories, setSelectedBikeInventories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [DefaultLocation, setDefaultLocation] = useState("");

  const [iAmTyping, setIAmTyping] = useState(false);

  const actionsList = options.action_m_types;

  const Technical =
    userType &&
    (fix || action === "quickcheckup") &&
    (userType == "technical" || userType == "manager" || userType == "admin")
      ? { display: "inline-table" }
      : { display: "none" };

  const setGeoLocation = async (e) => {
    setShowModal(false);
    setLatitude(e.latLng.lat());
    setLongitude(e.latLng.lng());

    if (e.latLng && e.latLng.lat()) {
      let data = await axios.get(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          e.latLng.lat() +
          "," +
          e.latLng.lng() +
          "&sensor=true&key=" +
          Constants.GOOGLEAPIKEY
      );

      var address_res = data && data.data ? data.data.results : [];

      for (var j = 0; j < address_res.length; j++) {
        var address_components = address_res[j].address_components;
        for (var i = 0; i < address_components.length; i++) {
          if (address_components[i].types[0] === "route") {
            setName(address_components[i].long_name);
            break;
          }
        }
      }
    }
  };

  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={10}
        defaultCenter={DefaultLocation}
        onClick={(e) => setGeoLocation(e)}
      >
        {props.lat && props.lng && (
          <Marker position={{ lat: latitude, lng: longitude }} />
        )}
      </GoogleMap>
    ))
  );

  /* Auto Suggest */

  const handleSuggestSearch = async (string) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    setStringSize(0);
    clearSuggestFields();
    setBikerName(string);
    if (string.length > 1) {
      setBikerList(await getBikersList(currentUser.branch, string, true));
      setStringSize(string.length);
    }

    //console.log(string, results)
  };

  const handleSuggestHover = (result) => {
    // we can use this to show more details about the biker on the future. So keep it.
    //console.log(result)
  };

  const handleSuggestSelect = (item) => {
    // the item selected
    if (item) {
      setBikerId(item.id);
      setBikerName(item.name);
    }
  };

  const handleSuggestFocus = () => {
    if (bikerName == "") {
      setBikerId("");
    }
  };
  const handleSuggestBlur = (item) => {
    if (!item) {
      clearSuggestFields();
    }
  };
  const clearSuggestFields = () => {
    setBikerId("");
    setBikerName("");
    setBikerList([]);
  };

  /* Category trigger */
  const triggerCategories = (cat, subCat) => {
    setCategory(cat);
    setSubCatOptions(categoryOptions[cat]);
    setSubCategory(subCat);
  };
  const triggerSetSubCategory = (subCat) => {
    setSubCategory(subCat);
  };

  const triggerActionCategories = (cat, subCat) => {
    setActionCategory(cat);
    setActionSubCatOptions(categoryOptions[cat]);
    setActionSubCategory(subCat);
  };

  const triggerSetAction = (act) => {
    setAction(act);
    if (act == "quickcheckup") {
      setSubcatergoryRequired(false);
      setCatergoryRequired(false);
    } else {
      setSubcatergoryRequired(true);
      setCatergoryRequired(true);
    }
  };

  const triggerTakeFromAnOtherBike = async (bikeObject) => {
    let bid = bikeObject.value;
    setBikeFromNumber(bikeObject);
    setIAmTyping(true);
    console.log("bid:", bid);
    let chosenBike = maintenanceBikes.filter((b) => b.value === bid)[0];

    if (chosenBike && chosenBike.model) {
      setSelectedBikeInventories(
        await getInventoriesOptions(
          "",
          "",
          branch,
          "",
          "",
          true,
          "",
          chosenBike.model
        )
      );
    }
  };
  const triggerSetType = async (type) => {
    setNumber("");
    setType(type);
    if (type === "bike") {
      setTypeList(Functions.getOptionsList(bikeList));
      setCategoryOptions([...categoryOptionsBike]);
    } else if (type === "charger") {
      setTypeList(Functions.getOptionsList(chargerList));
      setCategoryOptions([...categoryOptionsCharger]);
    } else if (type === "battery") {
      setTypeList(Functions.getOptionsList(batList));
      setCategoryOptions([...categoryOptionsBat]);
    }
  };

  //=================
  const getMaintenanceInfo = async () => {
    if (maintenanceId) {
      MaintenanceService.getMaintenanceById(maintenanceId).then(
        async (response) => {
          let b = response.data;
          setRealNumber(b.number);
          if (clone === true) {
            setBikeList(
              await getAvailableBikes(currentUser.branch, "maintenance")
            );
            setMaintenanceBikes(
              Functions.getOptionsList(
                await getAvailableBikes(
                  currentUser.branch,
                  "maintenance",
                  "",
                  "takefromBike"
                )
              )
            );
            setChargerList(
              await getAvailableChargers(currentUser.branch, "maintenance")
            );
            setBatList(
              await getAvailableBats(currentUser.branch, "maintenance")
            );
          } else if (b.bikeId) {
            setMaintenanceBikes(
              Functions.getOptionsList(
                await getAvailableBikes(
                  b.branch,
                  "maintenance",
                  "",
                  "takefromBike"
                )
              )
            );
            let bikeListTemp = await getAvailableBikes(b.branch, "maintenance");
            setBikeList([...bikeListTemp, b.bikeId]);
            setTypeList(Functions.getOptionsList([...bikeListTemp, b.bikeId]));
            setCategoryOptions([...categoryOptionsBike]);
            setBikeId(b.bikeId._id);
            await mainInfoHandler(b.bikeId._id, b.actionsDone, true);
            setNumber({ value: b.bikeId._id, label: b.bikeId.number });
            setOdometer(b.bikeId.kilometers);
            setType("bike");
            setBikeFromNumber(b.bikeFrom?._id);
          } else if (b.actionsDone) {
            b.actionsDone.forEach((item) => {
              var selectedAction = options.action_taken_fix.filter(
                (c) => c.key === item.action
              )[0];
              actionsDone.unshift({
                category: item.category,
                subCategory: item.subCategory,
                comments: item.comments,
                action: item.action,
                actionLabel: selectedAction.value,
                inventory: "",
                inventoryLabel: "",
              });
              setActionsDone(actionsDone);
            });
          }
          if (b.charger) {
            let chargerListTemp = await getAvailableChargers(
              b.branch,
              "maintenance"
            );
            setChargerList([...chargerListTemp, b.charger]);
            setTypeList(
              Functions.getOptionsList([...chargerListTemp, b.charger])
            );
            setCategoryOptions([...categoryOptionsCharger]);
            setChargerId(b.charger._id);
            setNumber({ value: b.charger._id, label: b.charger.number });
            setMainLogs(b.charger.logs);
            setType("charger");
          }
          if (b.battery) {
            let batListTemp = await getAvailableBats(b.branch, "maintenance");
            setBatList([...batListTemp, b.battery]);
            setTypeList(Functions.getOptionsList([...batListTemp, b.battery]));
            setCategoryOptions([...categoryOptionsBat]);
            setBatId(b.battery._id);
            setNumber({ value: b.battery._id, label: b.battery.number });
            setMainLogs(b.battery.logs);
            setType("battery");
          }

          if (b.repairedInventories) {
            setRepairedInventories([...b.repairedInventories]);
            setRepairedInventoriesInitial([...b.repairedInventories]);
          }
          if (b.recycledInventories) {
            setRecycledInventoriesInitial([...b.recycledInventories]);
            setRecycledInventories([...b.recycledInventories]);
          }
          if (b.lostInventories) {
            setlostInventoriesInitial([...b.lostInventories]);
            setlostInventories([...b.lostInventories]);
          }
          if (b.obsInventories) {
            setObsInventories([...b.obsInventories]);
            setObsInventoriesInitial([...b.obsInventories]);
          }
          setCompany(b.company ? b.company.name : "");
          setBikerId(b.usersubscribe ? b.usersubscribe._id : ""); //usersubscribe is the biker
          setBikerName(b.usersubscribe ? b.usersubscribe.full_name : ""); //usersubscribe is the biker
          handleSuggestSelect({
            id: b.usersubscribe ? b.usersubscribe._id : "",
            name: b.usersubscribe ? b.usersubscribe.full_name : "",
          });
          triggerCategories(b.category, b.subcategory);
          if (b.location && b.location.longitude && b.location.latitude) {
            setLongitude(parseFloat(b.location.longitude));
            setLatitude(parseFloat(b.location.latitude));
            setName(b.location.name);
          }

          triggerSetAction(b.action ? b.action : "repair");

          setStatus(b.status);
          setComments(b.comments);
          setBranch(b.branch);
          setDiagnose(b.diagnose);

          if (clone === true) {
            setMaintenanceId("");
          } // reset the id to clone
        },
        (error) => {
          const _content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          alert(_content);
          setMessage(_content);

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    } else {
      let bList = await getAvailableBikes(currentUser.branch, "maintenance");
      setBikeList(bList);
      setMaintenanceBikes(
        Functions.getOptionsList(
          await getAvailableBikes(
            currentUser.branch,
            "maintenance",
            "",
            "takefromBike"
          )
        )
      );

      setTypeList(Functions.getOptionsList(bList));
      setChargerList(
        await getAvailableChargers(currentUser.branch, "maintenance")
      );
      setBatList(await getAvailableBats(currentUser.branch, "maintenance"));
    }
  };

  useEffect(() => {
    async function onReady() {
      window.scrollTo(0, 0);
      let response = await CommonService.getPublicInfo();
      if (response)
        setDefaultLocation({
          lat: response.data.latitude,
          lng: response.data.longitude,
        });

      setCategoryOptions([...categoryOptionsBike]);

      setBranches(await getBranches());
      // get biker list
      //setBikerList(await getBikersList(currentUser.branch))

      if (userType != "admin") {
        setBranchClass("hide");
      }

      await getMaintenanceInfo();
    }

    onReady();
  }, []);

  const handleSaveMaintenance = async (e) => {
    e.preventDefault();
    if (number.value === "") {
      alert("Number field is required!");
      return;
    }
    var stop = false;
    var statusToSend = e.target.value;
    setIAmTyping(false);
    setMessage("");
    setSuccessful(false);
    setSaveClicked(true);
    form.current.validateAll();

    if (
      actionCategory != "" &&
      actionSubCategory != "" &&
      type == "bike" &&
      fixAction !== ""
    ) {
      await saveAction();
    }

    var repairedInventoriesFinal = repairedInventories.filter(
      (item) => item !== ""
    );
    var repairedInventoriesAddedFinal = repairedInventoriesAdded.filter(
      (item) => item !== ""
    );
    var repairedInventoriesRemovedFinal = repairedInventoriesRemoved.filter(
      (item) => item !== ""
    );

    var recycledInventoriesFinal = recycledInventories.filter(
      (item) => item !== ""
    );
    var recycledInventoriesAddedFinal = recycledInventoriesAdded.filter(
      (item) => item !== ""
    );
    var recycledInventoriesRemovedFinal = recycledInventoriesRemoved.filter(
      (item) => item !== ""
    );

    var lostInventoriesFinal = lostInventories.filter((item) => item !== "");
    var lostInventoriesAddedFinal = lostInventoriesAdded.filter(
      (item) => item !== ""
    );
    var lostInventoriesRemovedFinal = lostInventoriesRemoved.filter(
      (item) => item !== ""
    );

    var obsInventoriesFinal = obsInventories.filter((item) => item !== "");
    var obsInventoriesAddedFinal = obsInventoriesAdded.filter(
      (item) => item !== ""
    );
    var obsInventoriesRemovedFinal = obsInventoriesRemoved.filter(
      (item) => item !== ""
    );

    var specialInventoriesFinal = specialInventories.filter(
      (item) => item !== ""
    );
    var specialInventoriesAddedFinal = specialInventoriesAdded.filter(
      (item) => item !== ""
    );
    var specialInventoriesRemovedFinal = specialInventoriesRemoved.filter(
      (item) => item !== ""
    );

    repairedInventoriesFinal.forEach((item) => {
      if (recycledInventoriesFinal.includes(item)) {
        alert(
          "One Item is selected on Both Repaired and Recycled.. Plz Select One Action for this item"
        );
        stop = true;
        return;
      }

      if (lostInventoriesFinal.includes(item)) {
        alert(
          "One Item is selected on Both Repaired and lost.. Plz Select One Action for this item"
        );
        stop = true;
        return;
      }

      if (obsInventoriesFinal.includes(item)) {
        alert(
          "One Item is selected on Both Repaired and Obs.. Plz Select One Action for this item"
        );
        stop = true;
        return;
      }
    });

    recycledInventoriesFinal.forEach((item) => {
      if (obsInventoriesFinal.includes(item)) {
        alert(
          "One Item is selected on Both Recycle and Obs.. Plz Select One Action for this item"
        );
        stop = true;
        return;
      }
    });

    lostInventoriesFinal.forEach((item) => {
      if (obsInventoriesFinal.includes(item)) {
        alert(
          "One Item is selected on Both lost and Obs.. Plz Select One Action for this item"
        );
        stop = true;
        return;
      }
    });

    if (type == "bike" && odometer == "") {
      alert("Please fill the odometer for the bike.");
      //odometerRef.current.focus();
      stop = true;
      return;
    }
    if (stop == false) {
      var finalActions = [];
      actionsDone.forEach((item) => {
        finalActions.unshift({
          category: item.category,
          subCategory: item.subCategory,
          comments: item.comments,
          action: item.action,
          inventory: item.inventory,
          addOldPartTo: item.addOldPartTo,
          bikeNumber: item.bikeNumber,
          inventoryRemoved: item.inventoryRemoved,
        });
      });

      var selectedObj;
      if (type == "bike") {
        selectedObj = bikeList.filter((b) => b._id === bikeId)[0];
      } else if (type == "battery") {
        var selectedObj = batList.filter((b) => b._id === batId)[0];
      } else if (type == "charger") {
        var selectedObj = chargerList.filter((b) => b._id === chargerId)[0];
      }

      var data = {
        _id: maintenanceId,
        bikeId: bikeId,
        number: selectedObj ? selectedObj.number : "",
        bikerId: bikerId,
        charger: chargerId,
        battery: batId,
        category: category,
        subcategory: subCategory,
        location: {
          longitude: longitude,
          latitude: latitude,
          name: name,
        },
        comments: comments,
        diagnose: diagnose,
        kilometers: odometer,
        type: type,
        action: action,
        actionsDone: finalActions,
        branch: branch,
        status: statusToSend,
        repairedInventories: repairedInventoriesFinal,
        repairedInventoriesAdded: repairedInventoriesAddedFinal,
        repairedInventoriesRemoved: repairedInventoriesRemovedFinal,
        recycledInventories: recycledInventoriesFinal,
        recycledInventoriesAdded: recycledInventoriesAddedFinal,
        recycledInventoriesRemoved: recycledInventoriesRemovedFinal,
        lostInventories: lostInventoriesFinal,
        lostInventoriesAdded: lostInventoriesAddedFinal,
        lostInventoriesRemoved: lostInventoriesRemovedFinal,
        obsInventories: obsInventoriesFinal,
        obsInventoriesAdded: obsInventoriesAddedFinal,
        obsInventoriesRemoved: obsInventoriesRemovedFinal,
        specialInventories: specialInventoriesFinal,
        specialInventoriesAdded: specialInventoriesAddedFinal,
        specialInventoriesRemoved: specialInventoriesRemovedFinal,
        returnToBike: returnToBike,
        logs: logs,
      };

      if (checkBtn.current.context._errors.length === 0) {
        MaintenanceService.postMaintenance(data).then(
          (response) => {
            alert("Maintenances Saved.");
            setSuccessful(true);
            props.history.push("/maintenances");
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            setMessage(resMessage);
            setSuccessful(false);
          }
        );
      }
    }
  };

  const mainInfoHandler = async (
    selectedNumber,
    actionsAlreadyDone,
    ignoreSpecial
  ) => {
    if (selectedNumber === null) {
      setNumber({ value: "", label: "" });
      return;
    }
    let id = selectedNumber;
    if (selectedNumber.value) id = selectedNumber.value;
    setNumber(selectedNumber);
    if (type === "charger") {
      setChargerId(id);
      var selectedCharger = chargerList.filter((c) => c._id === id)[0];
      setMainLogs(selectedCharger.logs);
    } else if (type === "battery") {
      setBatId(id);
      var selectedBat = batList.filter((b) => b._id === id)[0];
      setMainLogs(selectedBat.logs);
    } else {
      setBikeId(id);
      if (id === "") return false;

      await MaintenanceService.getBikeInventoryById(id).then(
        ({ data }) => {
          if (data) {
            setBikeInventoryList(data.all);
            setMainLogs(data.logs);
            setMissingInventories(data.removedInventories || []);
            setSpecialOptions(data.special);
            setSpecialInventories(data.bikeInventories);
            setSpecialInventoriesInitial(data.bikeInventories);
            if (actionsAlreadyDone) {
              actionsAlreadyDone.forEach((item) => {
                var inventoryLabel = "";
                var invs = [data.all, ...data.special];

                var selectedAction = options.action_taken_fix.filter(
                  (c) => c.key === item.action
                )[0];
                var selectedInventory = invs.filter(
                  (i) => i._id === item.inventory
                )[0];

                if (selectedInventory) {
                  inventoryLabel =
                    selectedInventory.number +
                    " - " +
                    selectedInventory.label +
                    " - " +
                    selectedInventory.type;
                }

                actionsDone.unshift({
                  category: item.category,
                  subCategory: item.subCategory,
                  comments: item.comments,
                  action: item.action,
                  actionLabel: selectedAction ? selectedAction.value : "",
                  inventory: item.inventory,
                  addOldPartTo: item.addOldPartTo,
                  inventoryLabel: inventoryLabel,
                });
                setActionsDone(actionsDone);
              });
            }
          }
        },
        (error) => {
          alert("Bike not found");

          if (error.response && error.response.status === 401)
            EventBus.dispatch("logout");
        }
      );
    }
  };

  const handleBranchChange = async (id) => {
    setBranch(id);
    clearSuggestFields();
    //setBikerList(await getBikersList(id));

    if (type === "charger") {
      setTypeList(
        Functions.getOptionsList(await getAvailableChargers(id, "maintenance"))
      );
    } else if (type === "battery") {
      setTypeList(
        Functions.getOptionsList(await getAvailableBats(id, "maintenance"))
      );
    } else {
      setTypeList(
        Functions.getOptionsList(await getAvailableBikes(id, "maintenance"))
      );
      setMaintenanceBikes(
        Functions.getOptionsList(
          await getAvailableBikes(id, "maintenance", "", "takefromBike")
        )
      );
    }
  };

  const saveAction = async () => {
    if (
      actionCategory === "" ||
      actionSubCatOptions === "" ||
      fixAction === "" ||
      (type === "bike" && fixAction !== "fix" && inventory === "")
    ) {
      alert("Please fill all fields needs to save a technical action");
      return;
    }

    var selectedAction = options.action_taken_fix.filter(
      (c) => c.key === fixAction
    )[0];

    var inventoryLabel = "";

    var invs = [bikeInventoryList, ...specialOptions];

    if (type === "bike" && fixAction !== "fix") {
      var selectedInventory = invs.filter((i) => i._id === inventory)[0];
      inventoryLabel = selectedInventory
        ? selectedInventory.number +
          " - " +
          selectedInventory.label +
          " - " +
          selectedInventory.type
        : "";

      var id = selectedInventory ? selectedInventory._id : null;
      if (fixAction === "update") {
        if (!specialInventoriesInitial.includes(id)) {
          let temp = specialInventoriesAdded;
          temp.push({ id: id, addOldPartTo: addOldPartTo });
          setSpecialInventoriesAdded(temp);
        }

        specialInventories.unshift(id);
        setSpecialInventories(specialInventories);
      } else if (addOldPartTo == "repair") {
        if (!repairedInventoriesInitial.includes(id)) {
          let temp = repairedInventoriesAdded;
          temp.push(id);
          setRepairedInventoriesAdded(temp);
        }

        repairedInventories.unshift(id);
        setRepairedInventories(repairedInventories);
      } else if (addOldPartTo === "recycle") {
        if (!recycledInventoriesInitial.includes(id)) {
          let temp = recycledInventoriesAdded;
          temp.push(id);
          setRecycledInventoriesAdded(temp);
        }

        recycledInventories.unshift(id);
        setRecycledInventories(recycledInventories);
      } else if (addOldPartTo === "lost") {
        if (!lostInventoriesInitial.includes(id)) {
          let temp = lostInventoriesAdded;
          temp.push(id);
          setlostInventoriesAdded(temp);
        }

        lostInventories.unshift(id);
        setlostInventories(lostInventories);
      } else if (addOldPartTo === "obsolete") {
        if (!obsInventoriesInitial.includes(id)) {
          let temp = obsInventoriesAdded;
          temp.push(id);
          setObsInventoriesAdded(temp);
        }

        obsInventories.unshift(id);
        setObsInventories(obsInventories);
      }
    }

    let bikeNumber = null;

    if (type == "bike" && fixAction === "takeFromBike") {
      bikeNumber = bikeFrom ? bikeFrom.value : null;
    }
    actionsDone.unshift({
      category: actionCategory,
      subCategory: actionSubCategory,
      comments: actionComments,
      action: fixAction,
      actionLabel: selectedAction.value,
      inventory: inventory,
      inventoryLabel: inventoryLabel,
      addOldPartTo: addOldPartTo,
      bikeNumber: bikeNumber,
    });

    var oldpartStr =
      addOldPartTo != ""
        ? "- <span class='red'>Add old to " + addOldPartTo + "</span>"
        : "";

    var newLog =
      "Action Taken: <span class='green'>" +
      selectedAction.value +
      "</span> " +
      actionCategory +
      " - " +
      actionSubCategory +
      oldpartStr;

    if (inventory) {
      newLog += " - Inventory: " + inventoryLabel;
    }
    if (actionComments) {
      newLog += "<br> Comments: " + actionComments;
    }
    setLogs(newLog + "<br>" + logs);
    setMainLogs(newLog + "<br>" + mainLogs);

    setActionsDone(actionsDone);

    //reset
    setActionCategory("");
    setActionSubCatOptions([]);
    setActionSubCategory("");
    setActionComments("");
    setFixAction("");
    setInventory("");
    setAddOldPartTo("");
  };

  const removeAction = (i) => {
    setIAmTyping(true);
    var id = actionsDone[i].inventory;
    var addOldPartToRemove = actionsDone[i].addOldPartTo;
    var fixAction = actionsDone[i].action;
    var newLog =
      "<span class='crossedText'><span class='red'>Action Removed: " +
      fixAction +
      "</span> - category: " +
      actionsDone[i].category +
      " - sub category: " +
      actionsDone[i].subCategory;
    if (id) {
      newLog += " - Inventory: " + actionsDone[i].inventoryLabel;
    }
    if (actionsDone[i].comments) {
      newLog += "<br> Comments: " + actionsDone[i].comments;
    }
    newLog += "</span>";
    setLogs(newLog + "<br>" + logs);
    setMainLogs(newLog + "<br>" + mainLogs);

    const list = [...actionsDone];
    list.splice(i, 1);
    setActionsDone(list);

    if (type == "bike") {
      if (fixAction == "takeFromBike") {
        console.log("=========== actionsDone[i]:", actionsDone[i]);
        let temp = returnToBike;
        temp.push({ id: id, bike: actionsDone[i].bikeNumber });
        setReturnToBike(temp);
      }
      // if (fixAction == "update" || fixAction == "switch" || fixAction== "takeFromBike") {
      if (specialInventoriesInitial.includes(id)) {
        // inventory has been removed.
        let temp = specialInventoriesRemoved;
        temp.push({ id: id, addOldPartTo: addOldPartToRemove });
        setSpecialInventoriesRemoved(temp);
      }
      let index = specialInventories.indexOf(id);
      const list = [...specialInventories];
      list.splice(index, 1);
      setSpecialInventories(list);
    } else if (addOldPartToRemove == "repair") {
      if (repairedInventoriesInitial.includes(id)) {
        // inventory has been removed.
        let temp = repairedInventoriesRemoved;
        temp.push(id);
        setRepairedInventoriesRemoved(temp);
      }
      let index = repairedInventories.indexOf(id);
      const list = [...repairedInventories];
      list.splice(index, 1);
      setRepairedInventories(list);
    } else if (addOldPartToRemove == "recycle") {
      if (recycledInventoriesInitial.includes(id)) {
        // inventory has been removed.
        let temp = recycledInventoriesRemoved;
        temp.push(id);
        setRecycledInventoriesRemoved(temp);
      }
      let index = recycledInventories.indexOf(id);
      const list = [...recycledInventories];
      list.splice(index, 1);
      setRecycledInventories(list);
    } else if (addOldPartToRemove == "lost") {
      if (lostInventoriesInitial.includes(id)) {
        // inventory has been removed.
        let temp = lostInventoriesRemoved;
        temp.push(id);
        setlostInventoriesRemoved(temp);
      }
      let index = lostInventories.indexOf(id);
      const list = [...lostInventories];
      list.splice(index, 1);
      setlostInventories(list);
    } else if (addOldPartToRemove == "obsolete") {
      if (obsInventoriesInitial.includes(id)) {
        // inventory has been removed.
        let temp = obsInventoriesRemoved;
        temp.push(id);
        setObsInventoriesRemoved(temp);
      }
      let index = obsInventories.indexOf(id);
      const list = [...obsInventories];
      list.splice(index, 1);
      setObsInventories(list);
    }

    //}
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      setComments(comments + "\n");
    }
  };

  const handleDiagnoseKeyDown = (e) => {
    if (e.key === "Enter") {
      setDiagnose(diagnose + "\n");
    }
  };

  const handleActionCommentsKeyDown = (e) => {
    if (e.key === "Enter") {
      setActionComments(actionComments + "\n");
    }
  };

  return (
    <Fragment>
      <Prompt
        when={iAmTyping}
        message={(location) =>
          "Are you sure, you want to leave? All your data will be lost!"
        }
      />
      <div className="col-md-12">
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          style={{ minWidth: "80%" }}
          dialogClassName="mapModalDialog"
        >
          <Modal.Header>
            <Modal.Title>Choose from Map</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MyMapComponent
              lat={latitude}
              lng={longitude}
              googleMapURL={
                "https://maps.googleapis.com/maps/api/js?key=" +
                Constants.GOOGLEAPIKEY +
                "&libraries=places,visualization"
              }
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div style={{ height: `82vh`, width: `100%` }} />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </Modal.Body>
        </Modal>
        <Form
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
          ref={form}
        >
          {!successful && (
            <div>
              <table className="styled-table">
                <thead>
                  <tr>
                    <th style={{ width: "100%" }}>Basic Info</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="white-background">
                    <td>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: " baseline",
                        }}
                      >
                        {currentUser.type === "admin" ? (
                          <Col md={3}>
                            <div className="form-group">
                              <RequiredLabel htmlFor="branch" text="Branch" />
                              <Select
                                className="form-control"
                                value={branch}
                                onChange={(e) => {
                                  handleBranchChange(e.target.value);
                                  setIAmTyping(true);
                                }}
                                validations={[required]}
                              >
                                <option value=""></option>
                                {branches &&
                                  branches.map((m, index) => (
                                    <option key={index} value={m._id}>
                                      {m.label}
                                    </option>
                                  ))}
                              </Select>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col
                          style={HelpDesk}
                          md={3}
                          className="autoCompleteContainer"
                        >
                          {company ? (
                            <div className="form-group">
                              <label htmlFor="company" className="waveLabel">
                                Company
                                <br />
                              </label>
                              {company}
                              <br />
                            </div>
                          ) : (
                            <div className="form-group">
                              <OptionalLabel
                                htmlFor="bikerId"
                                text="Client Name"
                              />
                              <ReactSearchAutocomplete
                                styling={{
                                  borderRadius: 0,
                                  height: "calc(1.5em + 0.75rem )",
                                  border: "1px solid #ced4da",
                                  lineHeight: 1.5,
                                  zIndex: 1,
                                }}
                                items={bikerList}
                                onSearch={handleSuggestSearch}
                                onHover={handleSuggestHover}
                                onSelect={(v) => {
                                  handleSuggestSelect(v);
                                  setIAmTyping(true);
                                }}
                                onFocus={handleSuggestFocus}
                                onClear={clearSuggestFields}
                                showNoResults={false}
                                inputSearchString={bikerName}
                                showIcon={false}
                              />
                            </div>
                          )}
                        </Col>

                        <Col md={3}>
                          <div className="form-group">
                            <RequiredLabel htmlFor="type" text="Type" />
                            <Select
                              className="form-control"
                              disabled={maintenanceId ? true : false}
                              value={type}
                              onChange={(e) => {
                                triggerSetType(e.target.value);
                                setIAmTyping(true);
                              }}
                              validations={[required]}
                            >
                              <option value=""></option>
                              {options.model_types.map((item, index) => (
                                <option key={"type_" + index} value={item.key}>
                                  {item.value}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="form-group">
                            <RequiredLabel htmlFor="action" text="Action" />
                            <Select
                              className="form-control"
                              disabled={maintenanceId ? true : false}
                              value={action}
                              onChange={(e) => {
                                triggerSetAction(e.target.value);
                                setIAmTyping(true);
                              }}
                              validations={[required]}
                            >
                              <option value=""></option>
                              {Object.keys(actionsList).map((key, index) => {
                                return (
                                  <option key={"action_" + index} value={key}>
                                    {actionsList[key]}
                                  </option>
                                );
                              })}
                            </Select>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="form-group">
                            <RequiredLabel htmlFor="number" text="Number" />
                            <NewSelect
                              value={number}
                              onChange={(selectedNumber) => {
                                mainInfoHandler(selectedNumber);
                                setIAmTyping(true);
                              }}
                              options={typeList}
                              isClearable
                              isDisabled={maintenanceId}
                            />
                          </div>
                        </Col>
                        <Col md={3}>
                          <label htmlFor="status" className="waveLabel">
                            Status
                          </label>
                          {status}
                        </Col>

                        <Col md={3}>
                          <div className="form-group">
                            <OptionalLabel htmlFor="category" text="Category" />
                            <Select
                              className="form-control"
                              autoComplete="off"
                              name="category"
                              value={category}
                              onChange={(e) => {
                                triggerCategories(e.target.value, "");
                                setIAmTyping(true);
                              }}
                            >
                              <option value=""></option>
                              {categoryOptions.map((item, index) => (
                                <option key={"category" + index} value={item}>
                                  {item}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </Col>
                        {type == "bike" ? (
                          <Col md={3}>
                            <div className="form-group">
                              <OptionalLabel
                                htmlFor="sub_category"
                                text="Sub Category"
                              />
                              <Select
                                className="form-control"
                                autoComplete="off"
                                name="sub_category"
                                value={subCategory}
                                onChange={(e) => {
                                  triggerSetSubCategory(e.target.value);
                                  setIAmTyping(true);
                                }}
                              >
                                <option value=""></option>

                                {subCatOptions &&
                                  subCatOptions.map((item, index) => (
                                    <option
                                      key={"subcategory" + index}
                                      value={item}
                                    >
                                      {item}
                                    </option>
                                  ))}
                              </Select>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        {type == "bike" ? (
                          <Col>
                            <div className="form-group">
                              <RequiredLabel
                                htmlFor="Odometer"
                                text="Odometer"
                              />
                              <Input
                                type="number"
                                className="form-control wave-pulldown"
                                name="odometer"
                                value={odometer}
                                ref={odometerRef}
                                onChange={(e) => {
                                  setOdometer(e.target.value);
                                  setIAmTyping(true);
                                }}
                              />
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col md={12}>
                          <div className="form-group">
                            <OptionalLabel htmlFor="comments" text="Comments" />
                            <textarea
                              className="form-control"
                              name="comments"
                              autoComplete="off"
                              onKeyDown={(e) => handleKeyDown(e)}
                              onChange={(e) => {
                                setComments(e.target.value);
                                setIAmTyping(true);
                              }}
                              cols="30"
                              rows="4"
                              value={comments}
                            />
                          </div>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="styled-table">
                <thead>
                  <tr>
                    <th>Location</th>
                    <th style={{ textAlign: "right" }}>
                      <a
                        href="javascript:void(0)"
                        className="text-dark"
                        onClick={() => setShowModal(true)}
                      >
                        Choose from map
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="white-background">
                    <td colSpan={2}>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: " baseline",
                          marginBottom: "10px",
                        }}
                      >
                        <Col md={4}>
                          <div className="form-group">
                            <OptionalLabel
                              htmlFor="longitude"
                              text="Longitude"
                            />
                            <Input
                              type="text"
                              className="form-control wave-input"
                              name="longitude"
                              autoComplete="off"
                              value={longitude}
                              onChange={(e) => {
                                setLongitude(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="form-group">
                            <OptionalLabel htmlFor="name" text="Name" />
                            <Input
                              type="text"
                              className="form-control wave-input"
                              name="name"
                              autoComplete="off"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="form-group">
                            <OptionalLabel htmlFor="latitude" text="Latitude" />
                            <Input
                              type="text"
                              className="form-control wave-input"
                              name="latitude "
                              autoComplete="off"
                              value={latitude}
                              onChange={(e) => {
                                setLatitude(e.target.value);
                                setIAmTyping(true);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </table>

              {maintenanceId ? (
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th style={{ width: "100%" }}>Diagnose</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="white-background">
                      <td>
                        <Row
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: " baseline",
                          }}
                        >
                          <Col md={12}>
                            <div className="form-group">
                              <textarea
                                className="form-control"
                                name="diagnose"
                                autoComplete="off"
                                value={diagnose}
                                onKeyDown={(e) => handleDiagnoseKeyDown(e)}
                                onChange={(e) => {
                                  setDiagnose(e.target.value);
                                  setIAmTyping(true);
                                }}
                                cols="30"
                                rows="7"
                              />
                            </div>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                ""
              )}

              <RemovedInventories removedInventories={missingInventories} />

              <table className="styled-table" style={DoMaintenance}>
                <thead>
                  <tr>
                    <th style={{ width: "100%" }}>Logs</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="white-background">
                    <td>
                      <div className="form-group">
                        <div
                          dangerouslySetInnerHTML={{ __html: mainLogs }}
                        ></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="styled-table" style={Technical}>
                <thead>
                  <tr>
                    <th>Technical Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="white-background">
                    <td>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: " baseline",
                        }}
                      >
                        <Col md={6}>
                          <div className="form-group">
                            <RequiredLabel
                              htmlFor="actionCategory"
                              text="Category"
                            />
                            <Select
                              className="form-control"
                              autoComplete="off"
                              name="actionCategory"
                              value={actionCategory}
                              onChange={(e) => {
                                triggerActionCategories(e.target.value, "");
                                setIAmTyping(true);
                              }}
                            >
                              <option value=""></option>
                              {categoryOptions.map((item, index) => (
                                <option key={"category" + index} value={item}>
                                  {item}
                                </option>
                              ))}
                            </Select>
                          </div>
                        </Col>
                        {type == "bike" ? (
                          <Col md={6}>
                            <div className="form-group">
                              <RequiredLabel
                                htmlFor="actionSubCategory"
                                text="Sub Category"
                              />
                              <Select
                                className="form-control"
                                autoComplete="off"
                                name="actionSubCategory"
                                value={actionSubCategory}
                                onChange={(e) => {
                                  setActionSubCategory(e.target.value);
                                  setIAmTyping(true);
                                }}
                              >
                                <option value=""></option>

                                {actionSubCatOptions &&
                                  actionSubCatOptions.map((item, index) => (
                                    <option
                                      key={"subcategory" + index}
                                      value={item}
                                    >
                                      {item}
                                    </option>
                                  ))}
                              </Select>
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col md={6}>
                          <div className="form-group">
                            <RequiredLabel htmlFor="fixAction" text="Action" />
                            <Select
                              className="form-control"
                              value={fixAction}
                              onChange={(e) => {
                                setFixAction(e.target.value);
                                setIAmTyping(true);
                              }}
                            >
                              <option value=""></option>
                              {options.action_taken_fix.map((item, index) => {
                                if (action == "update" || item.key != "special")
                                  return (
                                    <option
                                      key={"type_" + index}
                                      value={item.key}
                                    >
                                      {item.value}
                                    </option>
                                  );
                              })}
                            </Select>
                          </div>
                        </Col>
                        {type == "bike" && fixAction === "takeFromBike" ? (
                          <Col md={6}>
                            <div
                              className="form-group"
                              style={{ marginRight: 10 }}
                            >
                              <RequiredLabel htmlFor="bikeFrom" text="Bike" />
                              <NewSelect
                                value={bikeFrom}
                                onChange={(bf) => {
                                  triggerTakeFromAnOtherBike(bf);
                                }}
                                options={maintenanceBikes}
                                isClearable
                              />
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {type == "bike" && fixAction !== "fix" ? (
                          <>
                            <Col md={6}>
                              <div
                                className="form-group"
                                style={{ marginRight: 10 }}
                              >
                                <RequiredLabel
                                  htmlFor="inventory"
                                  text="Inventory"
                                />
                                <Select
                                  className="form-control"
                                  name="inventory"
                                  value={inventory}
                                  onChange={(e) => {
                                    setInventory(e.target.value);
                                    setIAmTyping(true);
                                  }}
                                >
                                  {/* need to validate the Type: Do not accept frontwheel, backwheel and handel  */}
                                  <option value=""></option>
                                  {[
                                    ...bikeInventoryList,
                                    ...specialOptions,
                                  ].map((item, index) => {
                                    // check if the item matches the category

                                    if (
                                      actionSubCategory?.toLowerCase() ==
                                        item.type?.toLowerCase() ||
                                      actionSubCategory == "Other"
                                    )
                                      return (
                                        <option key={index} value={item._id}>
                                          {item.number} - {item.label} -{" "}
                                          {item.type}
                                        </option>
                                      );
                                  })}
                                </Select>
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className="form-group">
                                <RequiredLabel
                                  htmlFor="addOldPartTo"
                                  text="Add old part to"
                                />
                                <Select
                                  className="form-control"
                                  name="addOldPartTo"
                                  value={addOldPartTo}
                                  onChange={(e) => {
                                    setAddOldPartTo(e.target.value);
                                    setIAmTyping(true);
                                  }}
                                >
                                  {/* need to validate the Type: Do not accept frontwheel, backwheel and handel  */}
                                  <option value=""></option>
                                  {options.add_old_part_to.map(
                                    (item, index) => (
                                      <option key={index} value={item.key}>
                                        {item.value}
                                      </option>
                                    )
                                  )}
                                </Select>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}

                        <Col md={12}>
                          <div className="form-group">
                            <OptionalLabel
                              htmlFor="actionComments"
                              text="Comments"
                            />
                            <textarea
                              className="form-control"
                              name="actionComments"
                              autoComplete="off"
                              onKeyDown={(e) => handleActionCommentsKeyDown(e)}
                              onChange={(e) => {
                                setActionComments(e.target.value);
                                setIAmTyping(true);
                              }}
                              cols="10"
                              rows="5"
                              value={actionComments}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: " baseline",
                        }}
                      >
                        <Col md={12} style={{ align: "center" }}>
                          <a
                            className="mr10"
                            href="javascript:void(0)"
                            onClick={() => saveAction()}
                          >
                            Add
                          </a>
                        </Col>
                      </Row>
                    </td>
                  </tr>

                  {actionsDone &&
                    actionsDone.map((a, index) => (
                      <tr className="white-background">
                        <td>
                          <Row
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: " baseline",
                            }}
                          >
                            <Col md={2}>
                              <span className="tinyLabel">Category:</span>{" "}
                              {a.category}
                            </Col>
                            {type == "bike" ? (
                              <Col md={2}>
                                <span className="tinyLabel">Sub Category:</span>{" "}
                                {a.subCategory}
                              </Col>
                            ) : (
                              ""
                            )}
                            <Col md={3}>
                              <span className="tinyLabel">Action:</span>{" "}
                              {a.actionLabel}{" "}
                              {a.addOldPartTo != "" ? (
                                <> - Add old to {a.addOldPartTo}</>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col md={2}>
                              {type == "bike" && a.action !== "fix" ? (
                                <>
                                  <span className="tinyLabel">Inventory:</span>{" "}
                                  {a.inventoryLabel}
                                </>
                              ) : (
                                ""
                              )}
                            </Col>
                            <Col md={2}>{a.comments}</Col>
                            <Col md={1}>
                              {a.action !== "takeFromBike" ? (
                                <a
                                  className="mr10"
                                  href="javascript:void(0)"
                                  onClick={() => removeAction(index)}
                                >
                                  Remove
                                </a>
                              ) : (
                                ""
                              )}
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <div className="form-group">
                <table style={{ width: "100%" }} align="center">
                  <tbody>
                    <tr>
                      {action !== "quickcheckup" ? (
                        <td>
                          <button
                            onClick={(e) => handleSaveMaintenance(e)}
                            type="submit"
                            name="action"
                            value="Maintenance"
                            className="btn btn-primary btn-block"
                          >
                            Save
                          </button>
                        </td>
                      ) : (
                        ""
                      )}

                      {fix && action !== "quickcheckup" && type == "bike" ? (
                        <>
                          <td style={{ width: "20%" }}></td>
                          <td>
                            <button
                              onClick={(e) => handleSaveMaintenance(e)}
                              type="submit"
                              name="action"
                              value="Test"
                              className="btn btn-primary btn-block"
                            >
                              Test
                            </button>
                          </td>
                        </>
                      ) : null}

                      {action === "quickcheckup" ? (
                        <td>
                          <button
                            onClick={(e) => handleSaveMaintenance(e)}
                            type="submit"
                            name="action"
                            value="In Use"
                            className="btn btn-primary btn-block"
                          >
                            Return to Biker
                          </button>
                        </td>
                      ) : null}
                      {action !== "quickcheckup" ? (
                        <>
                          <td style={{ width: "20%" }}></td>
                          <td>
                            <button
                              onClick={(e) => handleSaveMaintenance(e)}
                              type="submit"
                              name="action"
                              value="Ready"
                              className="btn btn-primary btn-block"
                            >
                              Ready
                            </button>
                          </td>
                        </>
                      ) : null}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {message && (
            <div className="form-group">
              <div
                className={
                  successful ? "alert alert-success" : "alert alert-danger"
                }
                role="alert"
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </Fragment>
  );
}
